import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getStoresByCategory } from '../../config/securityInstance';

function CategoriesListingItems({ categoryName, location }) {
    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(true);
    const locationIcon = 'fi-bs-globe';
    useEffect(() => {
        const fetchStores = async () => {
            try {
                const storeData = await getStoresByCategory(categoryName);
                setStores(storeData);
            } catch (error) {
                console.error('Failed to fetch stores:', error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchStores();
    }, [categoryName]);

    if (loading) {
        return (
            <div className="flex flex-wrap justify-center items-center gap-4 overflow-auto max-h-[45vh]">
                {Array.from({ length: 4 }).map((_, index) => (
                    <div
                        key={index}
                        className="flex flex-col items-center border-2 rounded-xl mx-4 animate-pulse"
                    >
                        <div className="h-44 md:h-72 w-44 bg-gray-200"></div>
                        <div className="p-2">
                            <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
                            <div className="h-3 bg-gray-300 rounded w-1/2 mb-1"></div>
                            <div className="h-3 bg-gray-300 rounded w-1/3"></div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    if (stores.length === 0) {
        return <div>No stores found for this category.</div>;
    }

    return (
        <div className="grid grid-cols-1 md:grid-cols-3 justify-center items-center gap-4 overflow-auto max-h-[45vh]">
            {stores
                // .filter((item) => {
                //   const city = item.shopLocationDetails.shopCity;

                //   return (
                //     (location === null || city === location)
                //   );
                // })
                .map((items, storeIndex) => (
                    <Link key={storeIndex} to={`/s/${items._id}`}>
                        <div className="flex flex-row border-2 items-center overflow-hidden h-full md:max-h-48 rounded-xl mx-4">
                            <div className="flex-shrink-0">
                                {items.images.length > 0 ? (
                                    <img
                                        src={items.images[0].img1}
                                        alt="shop"
                                        className="h-44 md:h-72 w-44 object-cover"
                                    />
                                ) : (
                                    <div className="h-44 md:h-72 w-44 bg-gray-200 flex items-center justify-center">
                                        <span>No Image</span>
                                    </div>
                                )}
                            </div>
                            <div className="flex flex-col p-2 flex-grow">
                                <h3 className="text-base md:text-xl text-start font-semibold">
                                    {items.shopDetails.shopName}
                                </h3>
                                <div className="text-xs md:text-base font-normal md:font-semibold text-gray-500 text-left justify-center gap-2 px-0 md:px-4">
                                    <p className="flex items-center">
                                        <span className="p-2">
                                            <i
                                                className={`fi ${locationIcon}`}
                                            ></i>
                                        </span>{' '}
                                        {items.shopLocationDetails.shopLocation}
                                    </p>
                                    <p className="flex items-center">
                                        <span className="p-2">
                                            <i
                                                className={`fi ${locationIcon}`}
                                            ></i>
                                        </span>
                                        {items.shopLocationDetails.shopArea}
                                    </p>
                                    {items.shopDetails.shopWebsite && (
                                        <Link
                                            to={items.shopDetails.shopWebsite}
                                        >
                                            <p className="flex items-center">
                                                <span className="p-2">
                                                    <i
                                                        className={`fi ${locationIcon}`}
                                                    ></i>
                                                </span>{' '}
                                                {items.shopDetails.shopWebsite}
                                            </p>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
        </div>
    );
}

export default CategoriesListingItems;
