import React, { useEffect, useState } from 'react';
import TopHeadNameBar from '../../components/UI/TopHeadNameBar';
import { Link } from 'react-router-dom';
import SearchBox from '../../components/UI/SearchBox';
import { searchItems } from '../../data/searchData';
import { getAllCategories } from '../../config/securityInstance';

function CategoriesHome() {
    const [headName, setHeadName] = useState();
    const [categories, SetCategories] = useState([]);

    useEffect(() => {
        const fetchAllCategories = async () => {
            if (categories.length <= 0) {
                const response = await getAllCategories();
                if (response) {
                    const sortedCategories = response.sort((a, b) => {
                        if (a.label < b.label) return -1;
                        if (a.label > b.label) return 1;
                        return 0;
                    });
                    SetCategories(sortedCategories);
                }
            }
        };

        fetchAllCategories();
    }, [categories]);

    useEffect(() => {
        let tempName = window.location.pathname.replace('/', '');
        setHeadName(tempName);
    }, []);

    return (
        <div className="py-20">
            <TopHeadNameBar headName={headName} />
            <div className="flex justify-center my-12">
                <div className='px-8'>
                    <SearchBox searchItems={searchItems} />
                </div>
            </div>
            <div className="w-full flex justify-center">
                <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-6 w-10/12">
                    {categories.map((filteredItems, index) => (
                        <button key={index} className="p-2 md:p-8">
                            <Link to={`/c/${filteredItems.category}`}>
                                <div
                                    className={`w-full h-full flex flex-col rounded-xl p-4 space-y-3 hover:bg-gray-100 hover:text-blue-500 duration-150 hover:scale-110 hover:${filteredItems.hover}`}
                                >
                                    <p className="text-4xl items-center justify-center min-h-28 rounded-lg flex">
                                        <i
                                            className={`fi ${filteredItems.icon}`}
                                        ></i>
                                    </p>
                                    <p className="text-sm md:text-xl font-semibold items-center">
                                        {filteredItems.label}
                                    </p>
                                    <p className="text-base text-gray-500 font-semibold items-end">
                                        {filteredItems.count} Entries
                                    </p>
                                </div>
                            </Link>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default CategoriesHome;
