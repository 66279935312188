import React, { useEffect, useState } from 'react';
import TopHeadNameBarSearchQuery from '../../components/UI/TopHeadNameBarSearchQuery';
import SearchBox from '../../components/UI/SearchBox';
import { Link, useParams } from 'react-router-dom';
import { getAllStores } from '../../config/securityInstance.js';

function SearchQuery() {
    const { searchQuery } = useParams();
    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const locationIcon = 'fi-rr-marker';

    useEffect(() => {
        const fetchStores = async () => {
            try {
                const storeData = await getAllStores();
                setStores(storeData);
            } catch (error) {
                setError('Failed to fetch stores. Please try again later.');
                console.error('Failed to fetch stores:', error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchStores();
    }, []);

    if (loading) {
        return (
            <div className="flex flex-wrap justify-center items-center gap-4">
                {Array.from({ length: 4 }).map((_, index) => (
                    <div
                        key={index}
                        className="flex flex-col items-center border-2 rounded-xl mx-4 animate-pulse"
                    >
                        <div className="h-44 md:h-72 w-44 bg-gray-200"></div>
                        <div className="p-2">
                            <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
                            <div className="h-3 bg-gray-300 rounded w-1/2 mb-1"></div>
                            <div className="h-3 bg-gray-300 rounded w-1/3"></div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }

    if (error) {
        return <div>{error}</div>;
    }

    const filteredStores = stores.filter((store) => {
        const lowerQuery = searchQuery?.toLowerCase() || '';
        const lowerShopName = store.shopDetails?.shopName?.toLowerCase() || '';
        const lowerShopDescription =
            store.shopDetails?.shopDescription?.toLowerCase() || '';
        const lowerTagline = store.tagline?.toLowerCase() || ''; // Optional chaining for safety
        const queryWords = lowerQuery.split(' ');

        // Check if any word in the query matches shopName, shopDescription, or tagline
        return queryWords.some(
            (word) =>
                lowerShopName.includes(word) ||
                lowerShopDescription.includes(word) ||
                lowerTagline.includes(word)
        );
    });

    if (filteredStores.length === 0) {
        return (
            <div className="py-20">
                <TopHeadNameBarSearchQuery headName={searchQuery} />
                <div className="flex justify-center my-12">
                    <SearchBox />
                </div>
                <div className="flex justify-center items-center">
                    <div className="w-full">
                        <div>No stores found for this search.</div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="py-20">
            <TopHeadNameBarSearchQuery headName={searchQuery} />
            <div className="flex justify-center my-12 px-8">
                <SearchBox />
            </div>
            <div className="flex justify-center items-center">
                <div className="w-full">
                    <div className="grid grid-cols-1 md:grid-cols-3 justify-center items-center gap-4">
                        {filteredStores.map((items, storeIndex) => (
                            <Link key={storeIndex} to={`/s/${items._id}`}>
                                <div className="flex flex-row border-2 items-center overflow-hidden h-full md:max-h-48 rounded-xl mx-4">
                                    <div className="flex-shrink-0">
                                        {items.images.length > 0 ? (
                                            <img
                                                src={items.images[0].img1}
                                                alt="shop"
                                                className="h-44 md:h-72 w-44 object-cover"
                                            />
                                        ) : (
                                            <div className="h-44 md:h-72 w-44 bg-gray-200 flex items-center justify-center">
                                                <span>No Image</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex flex-col p-2 flex-grow">
                                        <h3 className="text-base md:text-xl text-start font-semibold">
                                            {items.shopDetails.shopName}
                                        </h3>
                                        <div className="text-xs md:text-base font-normal md:font-semibold text-gray-500 text-left justify-center gap-2 px-0 md:px-4">
                                            <p className="flex items-center">
                                                <span className="p-2">
                                                    <i
                                                        className={`fi ${locationIcon}`}
                                                    ></i>
                                                </span>{' '}
                                                {
                                                    items.shopLocationDetails
                                                        .shopLocation
                                                }
                                            </p>
                                            <p className="flex items-center">
                                                <span className="p-2">
                                                    <i
                                                        className={`fi ${locationIcon}`}
                                                    ></i>
                                                </span>
                                                {
                                                    items.shopLocationDetails
                                                        .shopArea
                                                }
                                            </p>
                                            {items.shopDetails.shopWebsite && (
                                                <Link
                                                    to={
                                                        items.shopDetails
                                                            .shopWebsite
                                                    }
                                                >
                                                    <p className="flex items-center">
                                                        <span className="p-2">
                                                            <i
                                                                className={`fi ${locationIcon}`}
                                                            ></i>
                                                        </span>{' '}
                                                        {
                                                            items.shopDetails
                                                                .shopWebsite
                                                        }
                                                    </p>
                                                </Link>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchQuery;
