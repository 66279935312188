const socialLink = [
    {
        disabled: true,
        iconName: 'Instagram',
        icon: 'fi-brands-instagram',
        link: 'https://instagram.com'
    },
    {
        iconName: 'Facebook',
        icon: 'fi-brands-facebook',
        link: 'https://facebook.com/erohtak'
    },
    {
        disabled: true,
        iconName: 'X',
        icon: 'fi-brands-twitter-alt-square',
        link: 'https://twitter.com'
    },
    {
        disabled: true,
        iconName: 'LinkedIn',
        icon: 'fi-brands-linkedin',
        link: 'https://linkedin.com'
    }
];

const useLink = [
    {
        linkName: 'Home',
        link: ''
    },
    {
        linkName: 'About Us',
        link: 'about-us'
    },
    {
        linkName: 'Categories',
        link: 'categories'
    },
    {
        linkName: 'Pricing',
        link: 'pricing'
    },
    {
        linkName: 'Contact Us',
        link: 'contact-us'
    }
];

const accLink = [
    {
        linkName: 'Login',
        link: 'login'
    },
    {
        linkName: 'Registration',
        link: 'login'
    },
    {
        linkName: 'Add Listing',
        link: 'add-listing'
    },
    {
        linkName: 'Terms And Condition',
        link: 'terms-and-condition'
    }
];

const contactLink = [
    {
        linkName: 'Our Address',
        linkDesc: 'Rohtak, Haryana 124001 INDIA',
        link: 'https://www.google.com/maps/place/Rohtak,+Haryana/',
        linkIcon: 'fi-bs-marker'
    },
    {
        linkName: 'Call Us',
        linkDesc: '+91 9068999989',
        link: 'tel:+919068999989',
        linkIcon: 'fi-bs-phone-call'
    },
    {
        linkName: 'Mail Us',
        linkDesc: 'support@erohtak.com',
        link: 'mailto:support@erohtak.com',
        linkIcon: 'fi-bs-envelope'
    }
];

export { accLink, contactLink, useLink, socialLink };
