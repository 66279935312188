import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllCategories, getAllStores } from '../../config/securityInstance';

export const SearchBox = () => {
    const [searchInput, setSearchInput] = useState('');
    const [category, setCategory] = useState('');
    const [where, setWhere] = useState('');
    const [categories, setCategories] = useState([]);
    const [storeNames, setStoreNames] = useState([]);
    const [allStores, setAllStores] = useState([]);
    const [area, setArea] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if (
                categories.length <= 0 ||
                storeNames.length <= 0 ||
                area.length <= 0
            ) {
                try {
                    const [fetchedCategories, fetchedStores, fetchedAreas] =
                        await Promise.all([
                            getAllCategories(),
                            getAllStores(),
                            getAllStores()
                        ]);

                    setCategories(
                        fetchedCategories.map((item) => ({
                            key: item.category,
                            value: item.label
                        }))
                    );

                    setStoreNames(
                        fetchedStores.map((item) => ({
                            key: item.shopDetails.shopName,
                            value: item.shopDetails.shopName
                        }))
                    );
                    setAllStores(fetchedStores);

                    const formattedAreas = fetchedAreas.map((item) => ({
                        key: item.shopLocationDetails?.shopArea || '',
                        value: item.shopLocationDetails?.shopArea || ''
                    }));

                    setArea(
                        Array.from(new Set(formattedAreas.map((a) => a.key)))
                            .sort()
                            .map((key) => ({ key, value: key }))
                    );
                } catch (error) {
                    toast.warn(
                        error.response?.data?.message || 'Failed to fetch data'
                    );
                }
            }
        };

        fetchData();
        if (searchInput !== '') {
            setCategory('');
            setWhere('');
        }
    }, [searchInput, categories, storeNames, area]);

    const handleSubmit = () => {
        if (searchInput) {
            navigate(`/search/${searchInput}`);
        } else if (category && where) {
            navigate(`/c/${category}?area=${where}`);
        } else if (category && !where) {
            navigate(`/c/${category}`);
        } else if (!category && where) {
            alert('Please Select Category also.');
        } else if (!searchInput && !category && !where) {
            alert('Please Select any of the Fields');
        }
    };

    const memoizedCategories = useMemo(() => categories, [categories]);
    const memoizedStoreNames = useMemo(() => storeNames, [storeNames]);
    const memoizedArea = useMemo(() => area, [area]);

    return (
        <div className="flex flex-col items-center space-y-6">
            <div className="w-full md:max-w-[40vw]">
                <label className="block text-2xl text-start font-semibold text-gray-800 mb-3">
                    Search
                </label>
                <input
                    type="text"
                    className="w-full border border-gray-300 px-4 py-1 rounded-xl shadow-sm focus:outline-none focus:border-blue-500 text-2xl"
                    placeholder="Type to search..."
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                />
            </div>

            {searchInput === '' && (
                <div className="flex flex-col md:flex-row justify-between w-full max-w-3xl gap-4">
                    <div className="flex-1 min-w-[250px]">
                        <label className="block text-lg text-start font-medium text-gray-700 mb-2">
                            Store Name
                        </label>
                        <select
                            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500 text-gray-700 text-lg"
                            value={storeNames}
                            disabled={category !== '' || where !== ''}
                            onChange={(e) => {
                                const selectedStoreName = e.target.value;
                                const selectedStore = allStores.find(
                                    (store) =>
                                        store.shopDetails.shopName ===
                                        selectedStoreName
                                );
                                if (selectedStore) {
                                    const storeId = selectedStore._id;
                                    navigate(`/s/${storeId}`);
                                } else {
                                    // Optionally handle the case where no store is found
                                    console.warn('Store not found');
                                }
                            }}
                        >
                            <option value="">Select a Store Name</option>
                            {memoizedStoreNames.map((item, index) => (
                                <option key={index} value={item.key}>
                                    {item.value}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex-1 min-w-[250px]">
                        <label className="block text-lg text-start font-medium text-gray-700 mb-2">
                            Category
                        </label>
                        <select
                            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500 text-gray-700 text-lg"
                            value={category}
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            <option value="">Select a Category</option>
                            {memoizedCategories.map((item) => (
                                <option key={item.key} value={item.key}>
                                    {item.value}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex-1 min-w-[250px]">
                        <label className="block text-lg text-start font-medium text-gray-700 mb-2">
                            Area
                        </label>
                        <select
                            className="w-full px-4 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:border-blue-500 text-gray-700 text-lg"
                            value={where}
                            onChange={(e) => setWhere(e.target.value)}
                        >
                            <option value="">Select an Area</option>
                            {memoizedArea.map((item) => (
                                <option key={item.key} value={item.key}>
                                    {item.value}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}

            <button
                onClick={handleSubmit}
                className="mt-4 bg-blue-600 hover:bg-blue-700 transition-colors duration-200 text-white py-3 px-8 rounded-xl shadow-md text-lg w-full font-semibold"
            >
                Search
            </button>
        </div>
    );
};

export default SearchBox;
