const shopCategory = [
  "",
  "All Categories",
  "AC Dealers",
  "AC Repair & Services",
  "App Developer",
  "ATM",
  "Automobile Spare Part Dealers",
  "Bakery",
  "Bakery Product Retailers",
  "Banks",
  "Battery Dealers",
  "Beauty Parlours",
  "Bed Dealers",
  "Book Shops",
  "Boutiques",
  "Builders & Developers",
  "Building Material Dealers",
  "CCTV Dealers",
  "Car Hire",
  "Car Repair & Services",
  "Caterers",
  "Cement Dealers",
  "Chemists",
  "Clinics",
  "Colleges",
  "Computer Dealers",
  "Computer Repair & Services",
  "Computer Training Institutes",
  "Cosmetic Dealers",
  "Cyber Cafes",
  "Dairy Product Retailers",
  "Dentists",
  "Designer Key Chain Dealers",
  "Developer",
  "Dining Table Dealers",
  "Electrical Shops",
  "Electricians",
  "Electronics",
  "Estate Agents",
  "Event Organisers",
  "Fabricators",
  "Fast Food",
  "Fertilizer Dealers",
  "Furniture Dealers",
  "Garages",
  "General Physician Doctors",
  "General Stores",
  "Gents Tailors",
  "Gift Shops",
  "Gold Jewellery Showrooms",
  "Gyms",
  "Hardware Dealers",
  "Hospitals",
  "Hotels",
  "Indian Restaurants",
  "Institutes",
  "Insurance Agents",
  "Interior Designers",
  "Inverter Dealers",
  "Jewellery Showrooms",
  "Ladoo Retailers",
  "Lawyers",
  "Loans",
  "Mobile Phone Accessory Dealers",
  "Mobile Phone Dealers",
  "Mobile Phone Repair & Services",
  "Motorcycle Repair & Services",
  "Nutritional Supplement Retailers",
  "Paint Dealers",
  "Pen Dealers",
  "Police Station",
  "Photo Studios",
  "Video Editor",
  "Animation",
  "Photographers",
  "Pipe Dealers",
  "Placement Services",
  "Printing Services",
  "Readymade Garment Retailers",
  "Refrigerator Repair & Services",
  "Restaurants",
  "Rice Retailers",
  "Salons",
  "Schools",
  "Scooter Repair & Services",
  "Shirt Retailers",
  "Shoe Dealers",
  "Sticker Dealers",
  "Sweet Shops",
  "TV Dealers",
  "Tailors",
  "Temples",
  "Tent House",
  "Tile Dealers",
  "Tool Dealers",
  "Transporters",
  "Travel Agents",
  "Tutorials",
  "Two Wheeler Repair & Services",
  "Tyre Dealers",
  "Website Developer",
  "Wire Dealers",
];

const whereLocation = [
  "",
  // "Abu",
  // "Adoni",
  // "Agartala",
  // "Agra",
  // "Ahmadabad",
  // "Ahmadnagar",
  // "Aizawl",
  // "Ajmer",
  // "Akola",
  // "Alappuzha",
  // "Aligarh",
  // "Alipore",
  // "Alipur Duar",
  // "Almora",
  // "Alwar",
  // "Amaravati",
  // "Ambala",
  // "Ambikapur",
  // "Amer",
  // "Amravati",
  // "Amreli",
  // "Amritsar",
  // "Amroha",
  // "Anantapur",
  // "Anantnag",
  // "Ara",
  // "Arcot",
  // "Asansol",
  // "Aurangabad",
  // "Ayodhya",
  // "Azamgarh",
  // "Baharampur",
  // "Bahraich",
  // "Balaghat",
  // "Balangir",
  // "Baleshwar",
  // "Ballia",
  // "Bally",
  // "Balurghat",
  // "Banda",
  // "Bankura",
  // "Bara Banki",
  // "Baramula",
  // "Baranagar",
  // "Barasat",
  // "Barauni",
  // "Bareilly",
  // "Baripada",
  // "Barmer",
  // "Barrackpore",
  // "Barwani",
  // "Basirhat",
  // "Basti",
  // "Batala",
  // "Beawar",
  // "Begusarai",
  // "Bettiah",
  // "Betul",
  // "Bhagalpur",
  // "Bhandara",
  // "Bharatpur",
  // "Bharhut",
  // "Bharuch",
  // "Bhatpara",
  // "Bhavnagar",
  // "Bhilai",
  // "Bhilwara",
  // "Bhind",
  // "Bhiwani",
  // "Bhojpur",
  // "Bhopal",
  // "Bhubaneshwar",
  // "Bhuj",
  // "Bhusawal",
  // "Bid",
  // "Bihar Sharif",
  // "Bijnor",
  // "Bikaner",
  // "Bilaspur",
  // "Bilaspur",
  // "Bishnupur",
  // "Bithur",
  // "Bodh Gaya",
  // "Bokaro",
  // "Bokaro",
  // "Brahmapur",
  // "Budaun",
  // "Budge Budge",
  // "Bulandshahr",
  // "Buldhana",
  // "Bundi",
  // "Burdwan",
  // "Burhanpur",
  // "Buxar",
  // "Chaibasa",
  // "Chaibasa",
  // "Chamba",
  // "Chandernagore",
  // "Chandigarh",
  // "Chandigarh",
  // "Chandigarh",
  // "Chandragiri",
  // "Chandrapur",
  // "Chapra",
  // "Chengalpattu",
  // "Chennai",
  // "Cherrapunji",
  // "Chhatarpur",
  // "Chhindwara",
  // "Chidambaram",
  // "Chittaurgarh",
  // "Chittoor",
  // "Churu",
  // "Coimbatore",
  // "Cooch Behar",
  // "Cuddalore",
  // "Cuttack",
  // "Dalhousie",
  // "Daman",
  // "Damoh",
  // "Darbhanga",
  // "Darjeeling",
  // "Datia",
  // "Daulatabad",
  // "Dehra Dun",
  // "Dehri",
  // "Delhi",
  // "Deoghar",
  // "Deoghar",
  // "Deoria",
  // "Dewas",
  // "Dhamtari",
  // "Dhanbad",
  // "Dhanbad",
  // "Dhar",
  // "Dharmapuri",
  // "Dharmshala",
  // "Dhaulpur",
  // "Dhenkanal",
  // "Dhuburi",
  // "Dhule",
  // "Diamond Harbour",
  // "Dibrugarh",
  // "Dinapur Nizamat",
  // "Dindigul",
  // "Dispur",
  // "Diu",
  // "Doda",
  // "Dowlaiswaram",
  // "Dr. Ambedkar Nagar (Mhow)",
  // "Dum Dum",
  // "Dumka",
  // "Dumka",
  // "Dungarpur",
  // "Durg",
  // "Durgapur",
  // "Dwarka",
  // "Eluru",
  // "Erode",
  // "Etah",
  // "Etawah",
  // "Faizabad",
  // "Faridabad",
  // "Faridkot",
  // "Farrukhabad-cum-Fatehgarh",
  // "Fatehpur",
  // "Fatehpur Sikri",
  // "Firozpur",
  // "Firozpur Jhirka",
  // "Gandhinagar",
  // "Ganganagar",
  // "Gangtok",
  // "Gaya",
  // "Ghaziabad",
  // "Ghazipur",
  // "Giridih",
  // "Giridih",
  // "Godhra",
  // "Gonda",
  // "Gorakhpur",
  // "Gulmarg",
  // "Guna",
  // "Guntur",
  // "Gurdaspur",
  // "Gurugram",
  // "Guwahati",
  // "Gwalior",
  // "Gyalshing",
  // "Hajipur",
  // "Halisahar",
  // "Hamirpur",
  // "Hamirpur",
  // "Hansi",
  // "Hanumangarh",
  // "Haora",
  // "Hardoi",
  // "Haridwar",
  // "Hathras",
  // "Hazaribag",
  // "Hazaribag",
  // "Hisar",
  // "Hoshangabad",
  // "Hoshiarpur",
  // "Hugli",
  // "Hyderabad",
  // "Idukki",
  // "Imphal",
  // "Indore",
  // "Ingraj Bazar",
  // "Itanagar",
  // "Itarsi",
  // "Jabalpur",
  // "Jagdalpur",
  // "Jaipur",
  // "Jaisalmer",
  // "Jalandhar",
  // "Jalaun",
  // "Jalgaon",
  // "Jalor",
  // "Jalpaiguri",
  // "Jamalpur",
  // "Jammu",
  // "Jamnagar",
  // "Jamshedpur",
  // "Jamshedpur",
  // "Jaunpur",
  // "Jhabua",
  // "Jhalawar",
  // "Jhansi",
  // "Jharia",
  // "Jharia",
  // "Jhunjhunu",
  // "Jind",
  // "Jodhpur",
  // "Jorhat",
  // "Junagadh",
  // "Kadapa",
  // "Kaithal",
  // "Kakinada",
  // "Kalimpong",
  // "Kalyan",
  // "Kamarhati",
  // "Kanchipuram",
  // "Kanchrapara",
  // "Kandla",
  // "Kangra",
  // "Kannauj",
  // "Kanniyakumari",
  // "Kannur",
  // "Kanpur",
  // "Kapurthala",
  // "Karaikal",
  // "Kargil",
  // "Karimnagar",
  // "Karli",
  // "Karnal",
  // "Kathua",
  // "Katihar",
  // "Kendujhar",
  // "Khajuraho",
  // "Khambhat",
  // "Khammam",
  // "Khandwa",
  // "Kharagpur",
  // "Khargone",
  // "Kheda",
  // "Kishangarh",
  // "Kochi",
  // "Kodaikanal",
  // "Kohima",
  // "Kolhapur",
  // "Kolkata",
  // "Kollam",
  // "Konark",
  // "Koraput",
  // "Kota",
  // "Kottayam",
  // "Kozhikode",
  // "Krishnanagar",
  // "Kullu",
  // "Kumbakonam",
  // "Kurnool",
  // "Kurukshetra",
  // "Lachung",
  // "Lakhimpur",
  // "Lalitpur",
  // "Leh",
  // "Lucknow",
  // "Ludhiana",
  // "Lunglei",
  // "Machilipatnam",
  // "Madgaon",
  // "Madhubani",
  // "Madurai",
  // "Mahabaleshwar",
  // "Mahbubnagar",
  // "Mahe",
  // "Mahesana",
  // "Maheshwar",
  // "Mainpuri",
  // "Malda",
  // "Malegaon",
  // "Mamallapuram",
  // "Mandi",
  // "Mandla",
  // "Mandsaur",
  // "Mangan",
  // "Matheran",
  // "Mathura",
  // "Mattancheri",
  // "Meerut",
  // "Merta",
  // "Midnapore",
  // "Mirzapur-Vindhyachal",
  // "Mon",
  // "Moradabad",
  // "Morbi",
  // "Morena",
  // "Motihari",
  // "Mumbai",
  // "Munger",
  // "Murshidabad",
  // "Murwara",
  // "Mussoorie",
  // "Muzaffarnagar",
  // "Muzaffarpur",
  // "Nabadwip",
  // "Nabha",
  // "Nadiad",
  // "Nagaon",
  // "Nagappattinam",
  // "Nagarjunakoṇḍa",
  // "Nagaur",
  // "Nagercoil",
  // "Nagpur",
  // "Nahan",
  // "Nainital",
  // "Nanded",
  // "Narsimhapur",
  // "Narsinghgarh",
  // "Narwar",
  // "Nashik",
  // "Nathdwara",
  // "Navsari",
  // "Neemuch",
  // "New Delhi",
  // "Nizamabad",
  // "Nowgong",
  // "Okha",
  // "Orchha",
  // "Osmanabad",
  // "Palakkad",
  // "Palanpur",
  // "Palashi",
  // "Palayamkottai",
  // "Pali",
  // "Panaji",
  // "Pandharpur",
  // "Panihati",
  // "Panipat",
  // "Panna",
  // "Paradip",
  // "Parbhani",
  // "Partapgarh",
  // "Patan",
  // "Patiala",
  // "Patna",
  // "Pehowa",
  // "Phalodi",
  // "Phek",
  // "Phulabani",
  // "Pilibhit",
  // "Pithoragarh",
  // "Porbandar",
  // "Prayagraj",
  // "Puducherry",
  // "Pudukkottai",
  // "Punch",
  // "Pune",
  // "Puri",
  // "Purnia",
  // "Purulia",
  // "Pusa",
  // "Pushkar",
  // "Rae Bareli",
  // "Raiganj",
  // "Raipur",
  // "Raisen",
  // "Rajahmundry",
  // "Rajapalayam",
  // "Rajgarh",
  // "Rajkot",
  // "Rajmahal",
  // "Rajmahal",
  // "Rajnandgaon",
  // "Rajouri",
  // "Ramanathapuram",
  // "Rampur",
  // "Ranchi",
  // "Ranchi",
  // "Ratlam",
  // "Ratnagiri",
  // "Rewa",
  // "Rewari",
  "Rohtak",
  // "Rupnagar",
  // "Sagar",
  // "Saharanpur",
  // "Saharsa",
  // "Salem",
  // "Samastipur",
  // "Sambalpur",
  // "Sambhal",
  // "Sangareddi",
  // "Sangli",
  // "Sangrur",
  // "Santipur",
  // "Saraikela",
  // "Saraikela",
  // "Sarangpur",
  // "Sasaram",
  // "Satara",
  // "Satna",
  // "Sawai Madhopur",
  // "Sehore",
  // "Seoni",
  // "Sevagram",
  // "Shahdol",
  // "Shahjahanpur",
  // "Shahpura",
  // "Shajapur",
  // "Shantiniketan",
  // "Sheopur",
  // "Shillong",
  // "Shimla",
  // "Shivpuri",
  // "Shrirampur",
  // "Sikar",
  // "Silchar",
  // "Siliguri",
  // "Silvassa",
  // "Sirohi",
  // "Sirsa",
  // "Sitamarhi",
  // "Sitapur",
  // "Siuri",
  // "Sivasagar",
  // "Siwan",
  // "Solapur",
  // "Sonipat",
  // "Srikakulam",
  // "Srinagar",
  // "Sultanpur",
  // "Surat",
  // "Surendranagar",
  // "Tamluk",
  // "Tehri",
  // "Tezpur",
  // "Thalassery",
  // "Thane",
  // "Thanjavur",
  // "Thiruvananthapuram",
  // "Thoothukudi",
  // "Thrissur",
  // "Tinsukia",
  // "Tiruchchirappalli",
  // "Tirunelveli",
  // "Tirupati",
  // "Tiruppur",
  // "Titagarh",
  // "Tonk",
  // "Udaipur",
  // "Udayagiri",
  // "Udhagamandalam",
  // "Udhampur",
  // "Ujjain",
  // "Ulhasnagar",
  // "Una",
  // "Valsad",
  // "Varanasi",
  // "Vasai-Virar",
  // "Vatakara",
  // "Vellore",
  // "Veraval",
  // "Vidisha",
  // "Vijayawada",
  // "Visakhapatnam",
  // "Vizianagaram",
  // "Warangal",
  // "Wardha",
  // "Wokha",
  // "Yanam",
  // "Yavatmal",
  // "Yemmiganur",
  // "Zunheboto",
];

const keywords = [
  "Abacus Classes",
  "AC Banquet Halls",
  "AC Dealers",
  "AC Repair & Services",
  "Accountants",
  "Accounting Services",
  "Accounts Tutorials",
  "Acid Dealers",
  "Acupressure Therapists",
  "Acupuncture Doctors",
  "Adhesive Dealers",
  "Advertising Agencies",
  "Agricultural Product Dealers",
  "Air Ticketing Agents",
  "Alarm System Dealers",
  "Aluminium Door Dealers",
  "Ambulance Services",
  "Angel Broking",
  "App Developer",
  "Animal Feed Dealers",
  "Aquarium Fish Dealers",
  "Aquariums",
  "Architects",
  "Army Training Centres",
  "Art Galleries",
  "Artists",
  "Ashrams",
  "Associations",
  "Asthma Doctors",
  "Astrologers",
  "ATM",
  "Audio Speaker Dealers",
  "Auto Rickshaw Dealers",
  "Auto Rickshaw Repair & Services",
  "Automobile Dealers",
  "Automobile Electrical Works",
  "Automobile Repair & Services",
  "Automobile Spare Part Dealers",
  "Axis Bank ATMS",
  "Ayurvedic Doctors",
  "Ayurvedic Medicine Shops",
  "Ayurvedic Product Dealers",
  "Ayurvedic Sexologist Doctors",
  "B Ed Colleges",
  "B. Com. Institutes",
  "B. Ed. Institutes",
  "Baby Care Product Dealers",
  "Bag Dealers",
  "Bag Manufacturers",
  "Bakery",
  "Bakery Product Retailers",
  "Ball Bearing Dealers",
  "Bamboo Dealers",
  "Bands",
  "Bangle Dealers",
  "Bank Exam Tutorials",
  "Bank Of Baroda",
  "Banks",
  "Banquet Halls",
  "Bathroom Fitting Dealers",
  "Battery Dealers",
  "Beauty Parlour Classes",
  "Beauty Parlours",
  "Beauty Product Dealers",
  "Bed Dealers",
  "Bed Sheet Retailers",
  "Beer Retailers",
  "Bicycle Dealers",
  "Bicycle Repair & Services",
  "Bill Payment Services",
  "Billiard Pool Parlours",
  "Biometric Attendance Recording System Dealers",
  "Biscuit Distributors",
  "Blanket Dealers",
  "Blood Collection Centres",
  "Blood Testing Centres",
  "Body Massage Centres",
  "Book Binding Services",
  "Book Publishers",
  "Book Shops",
  "Borewell Contractors",
  "Boutiques",
  "Brake Shoe Manufacturers",
  "Brick Dealers",
  "Bright Bar Manufacturers",
  "Builders & Developers",
  "Building Material Dealers",
  "Bulk SMS Services",
  "Bus On Hire",
  "Business Centres",
  "Business Consultants",
  "C&F Agents",
  "CA",
  "CA Tutorials",
  "Cable TV Operators",
  "Cake Shops",
  "Canara Bank ATMS",
  "Car AC Repair & Services",
  "Car Accessory Dealers",
  "Car Cleaning Services",
  "Car Dealers",
  "Car Denting Services",
  "Car Hire",
  "Car Painting Services",
  "Car Repair & Services",
  "Car Seat Cover Dealers",
  "Car Stereo Dealers",
  "Car Washing Services",
  "Carbide Die Manufacturers",
  "Cardiologists",
  "Carpenters",
  "Carpet Dealers",
  "Carrom Board Dealers",
  "Caterers",
  "CBSE Schools",
  "CCTV Dealers",
  "Cement Dealers",
  "Central Bank Of India ATMS",
  "Chaat Corners",
  "Chair Dealers",
  "Chalk Manufacturers",
  "Charitable Trusts",
  "Chemical Dealers",
  "Chemical Manufacturers",
  "Chemist Home Delivery",
  "Chemists",
  "Chicken Home Delivery",
  "Chicken Retailers",
  "Chicken Wholesalers",
  "Chocolate Retailers",
  "Choreographers",
  "Churches",
  "Cigarette Retailers",
  "Civil Contractors",
  "Cleaning Services",
  "Clinics",
  "Cloth Manufacturers",
  "Cloth Merchants",
  "Cloth Retailers",
  "Cloth Wholesalers",
  "Clubs",
  "Coal Dealers",
  "Coffee Shops",
  "Colleges",
  "Commission Agents",
  "Communication Center",
  "Computer Dealers",
  "Computer Graphic Designers",
  "Computer Printer Dealers",
  "Computer Printer Repair & Services",
  "Computer Repair & Services",
  "Computer Training Institutes",
  "Confectionery Retailers",
  "Construction Companies & Contractors",
  "Consultancy Services",
  "Contact Lens Dealers",
  "Contractors",
  "Cooler Manufacturers",
  "Corporate Companies",
  "Corrugated Box Manufacturers",
  "Cosmetic Dealers",
  "Costumes On Hire",
  "Cottages",
  "Cotton Fabric Wholesalers",
  "Cotton Manufacturers",
  "Courier Services",
  "CR Sheet Dealers",
  "Crane Service Providers",
  "Cranes On Hire",
  "Cricket Bat Dealers",
  "Cricket Clubs",
  "Cricket Coaching Classes",
  "Crockery Dealers",
  "CT Scan Centres",
  "Curtain Dealers",
  "Customer Care",
  "Cyber Cafes",
  "Dairy Farms",
  "Dairy Product Retailers",
  "Dal Retailers",
  "Video Editor",
  "Animation",
  "Dance Classes",
  "Data Cable Wholesalers",
  "Data Entry Job Works",
  "Decorative Door Manufacturers",
  "Decorators",
  "Defence Institutes",
  "Demolition Contractors",
  "Dental Hospitals",
  "Dental Surgeons",
  "Dentists",
  "Departmental Stores",
  "Software Developer",
  "Website Developer",
  "Dermatologists",
  "Designer Key Chain Dealers",
  "Designer Wear Retailers",
  "Detective Agencies",
  "Detergent Manufacturers",
  "Dhaba Restaurants",
  "Dharamshalas",
  "Dhol Players",
  "Dhoop Manufacturers",
  "Diagnostic Centres",
  "Diamond Jewellery Showrooms",
  "Diesel Engine Repair & Services",
  "Digital Marketing Services",
  "Dining Table Dealers",
  "Disc Jockey",
  "Disposable Product Dealers",
  "DJ Equipment Dealers",
  "DJ Equipment On Hire",
  "DJ System Dealers",
  "Dog Trainers",
  "Door Dealers",
  "Door Manufacturers",
  "Door Stopper Manufacturers",
  "Dowel Pin Manufacturers",
  "Dress Material Dealers",
  "Drill Dealers",
  "Driver Service Agents",
  "Dry Cleaners",
  "DTDC Express",
  "DTH TV Broadcast Service Providers",
  "DTP Services",
  "Dupatta Retailers",
  "DVD Shops",
  "Earthmovers On Hire-JCB",
  "Education Consultants",
  "Educational Institutes",
  "Egg Retailers",
  "Electrical Contractors",
  "Electrical Goods Dealers",
  "Electrical Goods Repair & Services",
  "Electrical Panel Manufacturers",
  "Electrical Shops",
  "Electricians",
  "Electronic Component Dealers",
  "Electronics",
  "Electroplating Job Works",
  "Electroplating Plant Dealers",
  "Elevator Dealers",
  "Elevator Repair & Services",
  "Embroidery Job Works",
  "Engine Oil Dealers",
  "Engineering Colleges",
  "Engineering Workshops",
  "Engineers",
  "English Medium Schools",
  "ENT Doctors",
  "EPABX Dealers",
  "Estate Agents",
  "Event Organisers",
  "Eye Clinics",
  "Eye Hospitals",
  "Eye Make UP Product Dealers",
  "Fabric Retailers",
  "Fabric Wholesalers",
  "Fabricators",
  "Fan Dealers",
  "Fan Repair & Services",
  "Fancy Dress Costume Retailers",
  "Farm House",
  "Fashion Designers",
  "Fast Food",
  "Fast Food Delivery Services",
  "Fastener Dealers",
  "Fertilizer Dealers",
  "Fibre Sheet Dealers",
  "Film Production Houses",
  "Finance Companies",
  "Finance Consultants",
  "Financial Advisers",
  "Fire Cracker Dealers",
  "Fire Extinguisher Dealers",
  "Fire Safety Equipment Dealers",
  "Fitness Centres",
  "Fitness Equipment Dealers",
  "Flex Banner Dealers",
  "Floor Wiper Manufacturers",
  "Florists",
  "Flour Manufacturers",
  "Flour Mill Dealers",
  "Flour Mills",
  "Flour Retailers",
  "Flower Shops",
  "FMCG Product Wholesalers",
  "Food Product Retailers",
  "Foreign Exchange Agents",
  "Forged Component Manufacturers",
  "Fruit Juice Retailers",
  "Fruit Vendors",
  "Fruit Wholesalers",
  "Furnishing Retailers",
  "Furniture Dealers",
  "Furniture Manufacturers",
  "Furniture Repair & Services",
  "Game Dealers",
  "Garages",
  "Garment Label Manufacturers",
  "Gas Agencies",
  "Gas Stove Dealers",
  "Gastroenterologists",
  "Gate Manufacturers",
  "Gearbox Manufacturers",
  "Gemstone Dealers",
  "General Physician Doctors",
  "General Stores",
  "General Stores Home Delivery",
  "General Surgeon Doctors",
  "Generator Dealers",
  "Generator Repair & Services",
  "Generators On Hire",
  "Gents Tailors",
  "Geyser Dealers",
  "Ghee Retailers",
  "Gift Shops",
  "Glass Dealers",
  "Glass Job Works",
  "Glue Dealers",
  "Gold Jewellery Showrooms",
  "Government Organisations",
  "Granite Dealers",
  "Grass Broom Manufacturers",
  "Grill Manufacturers",
  "Grocery Stores",
  "Groom Wear Retailers",
  "Group Stays",
  "GST Registration Consultants",
  "Guest House",
  "Gun Dealers",
  "Gurudwaras",
  "Gyms",
  "Gynaecologist & Obstetrician Doctors",
  "Hair Care Product Dealers",
  "Hair Stylists",
  "Handloom Retailers",
  "Handwriting Classes",
  "Hardware Dealers",
  "HDFC Bank ATMS",
  "Health Care Centres",
  "Health Care Consultants",
  "Health Care Product Retailers",
  "Health Clubs",
  "Herbal Product Dealers",
  "Home Appliance Dealers",
  "Home Appliance Repair & Services",
  "Home Delivery Laundry Services",
  "Home Delivery Restaurants",
  "Home Loans",
  "Home Stay",
  "Home Theatre System Dealers",
  "Home Tutors",
  "Homeopathic Doctors",
  "Homeopathic Medicine Retailers",
  "Hookah Dealers",
  "Hospitals",
  "Hostels",
  "Hotels",
  "House Painters",
  "Housekeeping Services",
  "Ice Cream Retailers",
  "Ice Dealers",
  "ICICI Banks",
  "IELTS Tutorials",
  "IIT Tutorials",
  "Imitation Jewellery Retailers",
  "Indian Restaurants",
  "Institutes",
  "Institutes For BBA",
  "Institutes For BCA",
  "Institutes For Distance Education",
  "Insurance Agents",
  "Insurance Companies",
  "Interior Decorators",
  "Interior Designers",
  "Internal Auditors",
  "International Courier Services",
  "Internet Service Providers",
  "Inverter Dealers",
  "Inverter Repair & Services",
  "Iron Merchants",
  "Iron Rod Dealers",
  "Iron Sheet Dealers",
  "Jack Dealers",
  "Jagran",
  "Jeans Retailers",
  "Jewellery Showrooms",
  "Juice Centres",
  "Juice Dealers",
  "Kindergartens",
  "Kirana Merchants",
  "Knee Cap Manufacturers",
  "Kurta Pyjama Retailers",
  "Kurti Dealers",
  "Labour Contractors",
  "Ladoo Retailers",
  "Lamination Services",
  "Lamp Dealers",
  "Language Classes For English",
  "Language Classes For Foreign Languages",
  "Laptop Dealers",
  "Laptop Repair & Services",
  "Lawyers",
  "Lead Sheet Manufacturers",
  "Leather Goods Dealers",
  "LED TV Dealers",
  "Legal Consultants",
  "Lemon Grass Oil Manufacturers",
  "Lens Dealers",
  "Library",
  "Life Insurance Agents",
  "Lift Manufacturers",
  "LPG Gas Pipe Line Installation Services",
  "Lubricant Dealers",
  "Machinery Manufacturers",
  "Machinery Spare Part Dealers",
  "Magnesium Sulphate Manufacturers",
  "Mall",
  "Management Institutes",
  "Mango Retailers",
  "Marble Dealers",
  "Market",
  "Marriage Bureaus",
  "Massage Centres",
  "Material Handling Equipment Dealers",
  "Maternity Hospitals",
  "Mattress Dealers",
  "Meat Shops",
  "Medical Equipment Dealers",
  "Medical Laboratories",
  "Medical Shops",
  "Medicine Wholesalers",
  "Meeting Rooms On Hire",
  "Metal Craft Manufacturers",
  "Metal Dealers",
  "Mineral Water Dealers",
  "Mineral Water Suppliers",
  "Mobile Accessory Dealers",
  "Mobile Phone Dealers",
  "Mobile Phone Repair & Services",
  "Modular Kitchen Dealers",
  "Money Transfer Agencies",
  "Moulding Machine Manufacturers",
  "Moving Services",
  "Music Classes",
  "Music Instrument Dealers",
  "Music Instrument Repair & Services",
  "Music Instrument Showrooms",
  "Nail Polish Wholesalers",
  "Namkeen Manufacturers",
  "Namkeen Retailers",
  "Namkeen Wholesalers",
  "Naturopathic Doctors",
  "Newspapers",
  "Night Clubs",
  "Night Watchmen Services",
  "Nursing Homes",
  "Nursery Schools",
  "Office Furniture Dealers",
  "Office Stationery Dealers",
  "Old Age Homes",
  "Opticians",
  "Orthopaedic Doctors",
  "Packers & Movers",
  "Paint Dealers",
  "Painting Contractors",
  "Pan Shops",
  "Panipuri Stalls",
  "Parcel Booking Services",
  "Party Lawns",
  "Party Planners",
  "Paving Block Manufacturers",
  "Paver Block Manufacturers",
  "Pet Shops",
  "Petrol Pumps",
  "Pharmacy Colleges",
  "Photo Studios",
  "Physiotherapists",
  "Pipe Dealers",
  "Placement Services",
  "Plastic Crate Manufacturers",
  "Plastic Granule Dealers",
  "Plastic Product Dealers",
  "Plumbing Contractors",
  "Ply Wood Dealers",
  "PMT Tutorials",
  "Police Station",
  "Polyester Yarn Manufacturers",
  "Pooja Samagri Dealers",
  "Popcorn Retailers",
  "Powder Coating Services",
  "Pre Primary Schools",
  "Press",
  "Pressure Cooker Dealers",
  "Private Hospitals",
  "Private Tutor Consultants",
  "Processors",
  "Property Consultants",
  "Provision Stores",
  "Public Schools",
  "Punjabi Restaurants",
  "PVC Door Dealers",
  "PVC Pipe Dealers",
  "Railway Ticketing Agents",
  "Real Estate Agents",
  "Ready Made Garment Retailers",
  "Ready Mix Concrete Dealers",
  "Real Estate Agents",
  "Real Estate Developers",
  "Ready Made Garment Retailers",
  "Ready Mix Concrete Dealers",
  "Recruitment Consultants",
  "Resorts",
  "Restaurant Management Consultants",
  "Restaurants",
  "Rubber Stamp Dealers",
  "Rug Dealers",
  "School Bag Dealers",
  "School Book Dealers",
  "Schools",
  "Sculpture Dealers",
  "Security Agencies",
  "Security System Dealers",
  "Seeds Wholesalers",
  "Sewing Machine Dealers",
  "Sewing Machine Repair & Services",
  "Shah",
  "Share Brokers",
  "Shoe Dealers",
  "Showrooms",
  "Snacks Retailers",
  "Soap Dealers",
  "Society",
  "Solar Product Dealers",
  "Souvenir Shops",
  "SPCE Coaching Classes",
  "Spectacle Dealers",
  "Spectacle Lens Dealers",
  "Spices Dealers",
  "Spice Exporters",
  "Spiritual Organisations",
  "Sports Clubs",
  "Sports Coaching Classes",
  "Stainless Steel Dealers",
  "Stationery Shops",
  "Steel Manufacturers",
  "Stone Dealers",
  "Supermarkets",
  "Sweet Shops",
  "Swimming Pool Contractors",
  "Tailors",
  "Tattoo Artists",
  "Taxi Services",
  "Tea Distributors",
  "Tea Retailers",
  "Temples",
  "Textile Dealers",
  "Timber Merchants",
  "Tile Dealers",
  "Tobacco Product Retailers",
  "Tool Dealers",
  "Toys Retailers",
  "Tractor Dealers",
  "Trailer Manufacturers",
  "Training Institutes",
  "Transporters",
  "Travel Agencies",
  "Tuition Centres",
  "Tyre Dealers",
  "Urologists",
  "Vehicle Insurance Agents",
  "Vehicle Loans",
  "Veterinary Doctors",
  "Video Shooting Services",
  "Visa Assistance",
  "Water Suppliers",
  "Welding Job Works",
  "Window Grill Manufacturers",
  "Wooden Box Dealers",
  "Wooden Furniture Dealers",
  "Wooden Flooring Dealers",
  "Yoga Classes",
  "Yoga Institutes",
];

const keywordsDropdown = [
  { value: "Abacus Classes", label: "Abacus Classes" },
  { value: "AC Banquet Halls", label: "AC Banquet Halls" },
  { value: "App Developer", label: "App Developer" },
  { value: "Video Editor", label: "Video Editor" },
  { value: "Developer", label: "Developer" },
  { value: "Website Developer", label: "Website Developer" },
  { value: "AC Dealers", label: "AC Dealers" },
  { value: "AC Repair & Services", label: "AC Repair & Services" },
  { value: "Accountants", label: "Accountants" },
  { value: "Accounting Services", label: "Accounting Services" },
  { value: "Accounts Tutorials", label: "Accounts Tutorials" },
  { value: "Acid Dealers", label: "Acid Dealers" },
  { value: "Acupressure Therapists", label: "Acupressure Therapists" },
  { value: "Acupuncture Doctors", label: "Acupuncture Doctors" },
  { value: "Adhesive Dealers", label: "Adhesive Dealers" },
  { value: "Advertising Agencies", label: "Advertising Agencies" },
  {
    value: "Agricultural Product Dealers",
    label: "Agricultural Product Dealers",
  },
  { value: "Air Ticketing Agents", label: "Air Ticketing Agents" },
  { value: "Alarm System Dealers", label: "Alarm System Dealers" },
  { value: "Aluminium Door Dealers", label: "Aluminium Door Dealers" },
  { value: "Ambulance Services", label: "Ambulance Services" },
  { value: "Angel Broking", label: "Angel Broking" },
  { value: "Animal Feed Dealers", label: "Animal Feed Dealers" },
  { value: "Aquarium Fish Dealers", label: "Aquarium Fish Dealers" },
  { value: "Aquariums", label: "Aquariums" },
  { value: "Architects", label: "Architects" },
  { value: "Army Training Centres", label: "Army Training Centres" },
  { value: "Art Galleries", label: "Art Galleries" },
  { value: "Artists", label: "Artists" },
  { value: "Ashrams", label: "Ashrams" },
  { value: "Associations", label: "Associations" },
  { value: "Asthma Doctors", label: "Asthma Doctors" },
  { value: "Astrologers", label: "Astrologers" },
  { value: "ATM", label: "ATM" },
  { value: "Audio Speaker Dealers", label: "Audio Speaker Dealers" },
  { value: "Auto Rickshaw Dealers", label: "Auto Rickshaw Dealers" },
  {
    value: "Auto Rickshaw Repair & Services",
    label: "Auto Rickshaw Repair & Services",
  },
  { value: "Automobile Dealers", label: "Automobile Dealers" },
  {
    value: "Automobile Electrical Works",
    label: "Automobile Electrical Works",
  },
  {
    value: "Automobile Repair & Services",
    label: "Automobile Repair & Services",
  },
  {
    value: "Automobile Spare Part Dealers",
    label: "Automobile Spare Part Dealers",
  },
  { value: "Axis Bank ATMS", label: "Axis Bank ATMS" },
  { value: "Ayurvedic Doctors", label: "Ayurvedic Doctors" },
  { value: "Ayurvedic Medicine Shops", label: "Ayurvedic Medicine Shops" },
  { value: "Ayurvedic Product Dealers", label: "Ayurvedic Product Dealers" },
  {
    value: "Ayurvedic Sexologist Doctors",
    label: "Ayurvedic Sexologist Doctors",
  },
  { value: "B Ed Colleges", label: "B Ed Colleges" },
  { value: "B. Com. Institutes", label: "B. Com. Institutes" },
  { value: "B. Ed. Institutes", label: "B. Ed. Institutes" },
  { value: "Baby Care Product Dealers", label: "Baby Care Product Dealers" },
  { value: "Bag Dealers", label: "Bag Dealers" },
  { value: "Bag Manufacturers", label: "Bag Manufacturers" },
  { value: "Bakery", label: "Bakery" },
  { value: "Bakery Product Retailers", label: "Bakery Product Retailers" },
  { value: "Ball Bearing Dealers", label: "Ball Bearing Dealers" },
  { value: "Bamboo Dealers", label: "Bamboo Dealers" },
  { value: "Bands", label: "Bands" },
  { value: "Bangle Dealers", label: "Bangle Dealers" },
  { value: "Bank Exam Tutorials", label: "Bank Exam Tutorials" },
  { value: "Bank Of Baroda", label: "Bank Of Baroda" },
  { value: "Banks", label: "Banks" },
  { value: "Banquet Halls", label: "Banquet Halls" },
  { value: "Bathroom Fitting Dealers", label: "Bathroom Fitting Dealers" },
  { value: "Battery Dealers", label: "Battery Dealers" },
  { value: "Beauty Parlour Classes", label: "Beauty Parlour Classes" },
  { value: "Beauty Parlours", label: "Beauty Parlours" },
  { value: "Beauty Product Dealers", label: "Beauty Product Dealers" },
  { value: "Bed Dealers", label: "Bed Dealers" },
  { value: "Bed Sheet Retailers", label: "Bed Sheet Retailers" },
  { value: "Beer Retailers", label: "Beer Retailers" },
  { value: "Bicycle Dealers", label: "Bicycle Dealers" },
  { value: "Bicycle Repair & Services", label: "Bicycle Repair & Services" },
  { value: "Bill Payment Services", label: "Bill Payment Services" },
  { value: "Billiard Pool Parlours", label: "Billiard Pool Parlours" },
  {
    value: "Biometric Attendance Recording System Dealers",
    label: "Biometric Attendance Recording System Dealers",
  },
  { value: "Biscuit Distributors", label: "Biscuit Distributors" },
  { value: "Blanket Dealers", label: "Blanket Dealers" },
  { value: "Blood Collection Centres", label: "Blood Collection Centres" },
  { value: "Blood Testing Centres", label: "Blood Testing Centres" },
  { value: "Body Massage Centres", label: "Body Massage Centres" },
  { value: "Book Binding Services", label: "Book Binding Services" },
  { value: "Book Publishers", label: "Book Publishers" },
  { value: "Book Shops", label: "Book Shops" },
  { value: "Borewell Contractors", label: "Borewell Contractors" },
  { value: "Boutiques", label: "Boutiques" },
  { value: "Brake Shoe Manufacturers", label: "Brake Shoe Manufacturers" },
  { value: "Brick Dealers", label: "Brick Dealers" },
  { value: "Bright Bar Manufacturers", label: "Bright Bar Manufacturers" },
  { value: "Builders & Developers", label: "Builders & Developers" },
  { value: "Building Material Dealers", label: "Building Material Dealers" },
  { value: "Bulk SMS Services", label: "Bulk SMS Services" },
  { value: "Bus On Hire", label: "Bus On Hire" },
  { value: "Business Centres", label: "Business Centres" },
  { value: "Business Consultants", label: "Business Consultants" },
  { value: "C&F Agents", label: "C&F Agents" },
  { value: "CA", label: "CA" },
  { value: "CA Tutorials", label: "CA Tutorials" },
  { value: "Cable TV Operators", label: "Cable TV Operators" },
  { value: "Cake Shops", label: "Cake Shops" },
  { value: "Canara Bank ATMS", label: "Canara Bank ATMS" },
  { value: "Car AC Repair & Services", label: "Car AC Repair & Services" },
  { value: "Car Accessory Dealers", label: "Car Accessory Dealers" },
  { value: "Car Cleaning Services", label: "Car Cleaning Services" },
  { value: "Car Dealers", label: "Car Dealers" },
  { value: "Car Denting Services", label: "Car Denting Services" },
  { value: "Car Hire", label: "Car Hire" },
  { value: "Car Painting Services", label: "Car Painting Services" },
  { value: "Car Repair & Services", label: "Car Repair & Services" },
  { value: "Car Seat Cover Dealers", label: "Car Seat Cover Dealers" },
  { value: "Car Stereo Dealers", label: "Car Stereo Dealers" },
  { value: "Car Washing Services", label: "Car Washing Services" },
  { value: "Carbide Die Manufacturers", label: "Carbide Die Manufacturers" },
  { value: "Cardiologists", label: "Cardiologists" },
  { value: "Carpenters", label: "Carpenters" },
  { value: "Carpet Dealers", label: "Carpet Dealers" },
  { value: "Carrom Board Dealers", label: "Carrom Board Dealers" },
  { value: "Caterers", label: "Caterers" },
  { value: "CBSE Schools", label: "CBSE Schools" },
  { value: "CCTV Dealers", label: "CCTV Dealers" },
  { value: "CCTV Repair & Services", label: "CCTV Repair & Services" },
  { value: "Ceiling Fan Dealers", label: "Ceiling Fan Dealers" },
  { value: "Cement Dealers", label: "Cement Dealers" },
  { value: "Chartered Accountants", label: "Chartered Accountants" },
  { value: "Chemical Dealers", label: "Chemical Dealers" },
  { value: "Chemists", label: "Chemists" },
  { value: "Child Care Centres", label: "Child Care Centres" },
  { value: "Children Hospitals", label: "Children Hospitals" },
  { value: "Chinese Restaurants", label: "Chinese Restaurants" },
  { value: "Chocolate Retailers", label: "Chocolate Retailers" },
  { value: "Civil Contractors", label: "Civil Contractors" },
  { value: "Clinics", label: "Clinics" },
  { value: "Coaching Classes", label: "Coaching Classes" },
  { value: "Coconut Retailers", label: "Coconut Retailers" },
  { value: "Coffee Shops", label: "Coffee Shops" },
  { value: "Colleges", label: "Colleges" },
  { value: "Commercial Architects", label: "Commercial Architects" },
  { value: "Commercial Photographers", label: "Commercial Photographers" },
  { value: "Company Secretaries", label: "Company Secretaries" },
  { value: "Computer Dealers", label: "Computer Dealers" },
  { value: "Computer Repair & Services", label: "Computer Repair & Services" },
  {
    value: "Computer Training Institutes",
    label: "Computer Training Institutes",
  },
  { value: "Conference Halls", label: "Conference Halls" },
  { value: "Consultants", label: "Consultants" },
  { value: "Continental Restaurants", label: "Continental Restaurants" },
  { value: "Cooking Classes", label: "Cooking Classes" },
  { value: "Cooking Oil Dealers", label: "Cooking Oil Dealers" },
  { value: "Corporate Gift Dealers", label: "Corporate Gift Dealers" },
  { value: "Cosmetic Dealers", label: "Cosmetic Dealers" },
  { value: "Courier Services", label: "Courier Services" },
  { value: "Courts", label: "Courts" },
  { value: "Crane Dealers", label: "Crane Dealers" },
  { value: "Crane Services", label: "Crane Services" },
  { value: "Credit Card Agents", label: "Credit Card Agents" },
  { value: "Crockery Dealers", label: "Crockery Dealers" },
  { value: "Curtain Dealers", label: "Curtain Dealers" },
  { value: "Cyber Cafes", label: "Cyber Cafes" },
  { value: "Cycle Dealers", label: "Cycle Dealers" },
  { value: "Cycle Repair & Services", label: "Cycle Repair & Services" },
  { value: "Dance Classes", label: "Dance Classes" },
  { value: "Data Entry Centres", label: "Data Entry Centres" },
  { value: "Day Care Centres", label: "Day Care Centres" },
  { value: "Decorators", label: "Decorators" },
  { value: "Dentists", label: "Dentists" },
  { value: "Departmental Stores", label: "Departmental Stores" },
  { value: "Designing Institutes", label: "Designing Institutes" },
  { value: "Developers", label: "Developers" },
  { value: "Dieticians", label: "Dieticians" },
  { value: "Dining Table Dealers", label: "Dining Table Dealers" },
  { value: "Doctors", label: "Doctors" },
  { value: "Dress Material Retailers", label: "Dress Material Retailers" },
  { value: "Dry Cleaners", label: "Dry Cleaners" },
  { value: "DTH Services", label: "DTH Services" },
  { value: "Electric Motor Dealers", label: "Electric Motor Dealers" },
  { value: "Electrical Shops", label: "Electrical Shops" },
  { value: "Electricians", label: "Electricians" },
  {
    value: "Electronics",
    label: "Electronics",
  },
  { value: "Police Station", label: "Police Station" },
  {
    value: "Embroidery Material Dealers",
    label: "Embroidery Material Dealers",
  },
  { value: "Engine Oil Dealers", label: "Engine Oil Dealers" },
  { value: "Engineers", label: "Engineers" },
  { value: "Event Management Companies", label: "Event Management Companies" },
  { value: "Event Organisers", label: "Event Organisers" },
  { value: "Exam Centres", label: "Exam Centres" },
  { value: "Eye Clinics", label: "Eye Clinics" },
  { value: "Eye Specialists", label: "Eye Specialists" },
  { value: "Fabricators", label: "Fabricators" },
  { value: "Fast Food", label: "Fast Food" },
  { value: "Fertilizer Dealers", label: "Fertilizer Dealers" },
  { value: "Film Distributors", label: "Film Distributors" },
  { value: "Financial Consultants", label: "Financial Consultants" },
  { value: "Financial Planners", label: "Financial Planners" },
  { value: "Financial Services", label: "Financial Services" },
  { value: "Fire Alarm System Dealers", label: "Fire Alarm System Dealers" },
  { value: "Fire Extinguisher Dealers", label: "Fire Extinguisher Dealers" },
  { value: "Fish Retailers", label: "Fish Retailers" },
  { value: "Fitness Centres", label: "Fitness Centres" },
  { value: "Florists", label: "Florists" },
  { value: "Flour Mill Dealers", label: "Flour Mill Dealers" },
  { value: "Flower Decorators", label: "Flower Decorators" },
  { value: "Footwear Dealers", label: "Footwear Dealers" },
  { value: "Foundation Schools", label: "Foundation Schools" },
  { value: "FRP Product Dealers", label: "FRP Product Dealers" },
  { value: "Furniture Dealers", label: "Furniture Dealers" },
  { value: "Garages", label: "Garages" },
  { value: "Garment Retailers", label: "Garment Retailers" },
  { value: "Garment Wholesalers", label: "Garment Wholesalers" },
  { value: "Gas Agencies", label: "Gas Agencies" },
  { value: "Gas Cylinder Dealers", label: "Gas Cylinder Dealers" },
  { value: "General Physicians", label: "General Physicians" },
  { value: "General Stores", label: "General Stores" },
  { value: "Gents Tailors", label: "Gents Tailors" },
  { value: "Gift Dealers", label: "Gift Dealers" },
  { value: "Gift Shops", label: "Gift Shops" },
  { value: "Ginning Mill", label: "Ginning Mill" },
  { value: "Glass Dealers", label: "Glass Dealers" },
  { value: "Gold Buyers", label: "Gold Buyers" },
  {
    value: "Gold Jewellery Manufacturers",
    label: "Gold Jewellery Manufacturers",
  },
  { value: "Gold Jewellery Showrooms", label: "Gold Jewellery Showrooms" },
  { value: "Grocery Stores", label: "Grocery Stores" },
  { value: "Gyms", label: "Gyms" },
  { value: "Hair Weaving Services", label: "Hair Weaving Services" },
  { value: "Hardware Dealers", label: "Hardware Dealers" },
  { value: "Hardware Repair & Services", label: "Hardware Repair & Services" },
  { value: "Hardware Shops", label: "Hardware Shops" },
  { value: "Hearing Aid Dealers", label: "Hearing Aid Dealers" },
  { value: "Home Appliance Dealers", label: "Home Appliance Dealers" },
  {
    value: "Home Appliance Repair & Services",
    label: "Home Appliance Repair & Services",
  },
  { value: "Home Decorators", label: "Home Decorators" },
  { value: "Homeopathic Doctors", label: "Homeopathic Doctors" },
  { value: "Honey Dealers", label: "Honey Dealers" },
  { value: "Hosiery Dealers", label: "Hosiery Dealers" },
  { value: "Hospitals", label: "Hospitals" },
  { value: "Hotels", label: "Hotels" },
  { value: "Housekeeping Services", label: "Housekeeping Services" },
  { value: "Housing Loan Consultants", label: "Housing Loan Consultants" },
  { value: "IATA Approved Agents", label: "IATA Approved Agents" },
  { value: "ICU", label: "ICU" },
  { value: "Ice Cream Dealers", label: "Ice Cream Dealers" },
  { value: "ID Card Dealers", label: "ID Card Dealers" },
  {
    value: "Industrial Equipment Dealers",
    label: "Industrial Equipment Dealers",
  },
  {
    value: "Industrial Equipment Repair & Services",
    label: "Industrial Equipment Repair & Services",
  },
  {
    value: "Industrial Training Institutes",
    label: "Industrial Training Institutes",
  },
  { value: "Industries", label: "Industries" },
  { value: "Inverter Dealers", label: "Inverter Dealers" },
  { value: "Jewellery Shops", label: "Jewellery Shops" },
  { value: "Juice Centres", label: "Juice Centres" },
  { value: "Kitchen Appliance Dealers", label: "Kitchen Appliance Dealers" },
  { value: "Labour Contractors", label: "Labour Contractors" },
  { value: "Ladies Tailors", label: "Ladies Tailors" },
  { value: "Lampshade Dealers", label: "Lampshade Dealers" },
  { value: "Laptop Dealers", label: "Laptop Dealers" },
  { value: "Laptop Repair & Services", label: "Laptop Repair & Services" },
  { value: "Laser Cutting Services", label: "Laser Cutting Services" },
  { value: "Laundry Services", label: "Laundry Services" },
  { value: "Lawn Mower Dealers", label: "Lawn Mower Dealers" },
  { value: "Lawyers", label: "Lawyers" },
  { value: "Leather Bag Dealers", label: "Leather Bag Dealers" },
  { value: "Libraries", label: "Libraries" },
  { value: "Loan Consultants", label: "Loan Consultants" },
  { value: "Logistics Services", label: "Logistics Services" },
  { value: "Luggage Dealers", label: "Luggage Dealers" },
  { value: "Luxurious Car Services", label: "Luxurious Car Services" },
  { value: "Magnet Dealers", label: "Magnet Dealers" },
  { value: "Malaria Treatment Centres", label: "Malaria Treatment Centres" },
  { value: "Mall", label: "Mall" },
  { value: "Manufacturers", label: "Manufacturers" },
  { value: "Marketing Consultants", label: "Marketing Consultants" },
  { value: "Marriage Halls", label: "Marriage Halls" },
  {
    value: "Material Handling Equipment Dealers",
    label: "Material Handling Equipment Dealers",
  },
  { value: "Mattress Dealers", label: "Mattress Dealers" },
  { value: "Medical Book Dealers", label: "Medical Book Dealers" },
  { value: "Medical Equipment Dealers", label: "Medical Equipment Dealers" },
  {
    value: "Medical Equipment Repair & Services",
    label: "Medical Equipment Repair & Services",
  },
  { value: "Medical Shops", label: "Medical Shops" },
  { value: "Medicine Dealers", label: "Medicine Dealers" },
  { value: "Milk Dealers", label: "Milk Dealers" },
  { value: "Mineral Water Dealers", label: "Mineral Water Dealers" },
  { value: "Mobile Accessory Dealers", label: "Mobile Accessory Dealers" },
  { value: "Mobile Phone Dealers", label: "Mobile Phone Dealers" },
  {
    value: "Mobile Phone Repair & Services",
    label: "Mobile Phone Repair & Services",
  },
  { value: "Modular Kitchen Dealers", label: "Modular Kitchen Dealers" },
  { value: "Money Transfer Services", label: "Money Transfer Services" },
  { value: "Motorcycle Dealers", label: "Motorcycle Dealers" },
  {
    value: "Motorcycle Repair & Services",
    label: "Motorcycle Repair & Services",
  },
  { value: "Motors", label: "Motors" },
  { value: "Nail Art Services", label: "Nail Art Services" },
  { value: "Natural Ice Cream", label: "Natural Ice Cream" },
  { value: "Naturopathic Doctors", label: "Naturopathic Doctors" },
  { value: "Newspaper Dealers", label: "Newspaper Dealers" },
  { value: "Notaries", label: "Notaries" },
  { value: "Nuts Dealers", label: "Nuts Dealers" },
  { value: "Nuts Retailers", label: "Nuts Retailers" },
  { value: "Office Furniture Dealers", label: "Office Furniture Dealers" },
  { value: "Office Stationery Dealers", label: "Office Stationery Dealers" },
  { value: "Oil Dealers", label: "Oil Dealers" },
  { value: "Opticals", label: "Opticals" },
  { value: "Opticians", label: "Opticians" },
  { value: "Organic Food Dealers", label: "Organic Food Dealers" },
  { value: "Orthopedic Doctors", label: "Orthopedic Doctors" },
  { value: "Packers & Movers", label: "Packers & Movers" },
  { value: "Paint Dealers", label: "Paint Dealers" },
  { value: "Painter", label: "Painter" },
  { value: "Hand Car Dealers", label: "Second Hand Car Dealers" },
  { value: "Security Services", label: "Security Services" },
  { value: "Seeds Dealers", label: "Seeds Dealers" },
  { value: "Self Defence Classes", label: "Self Defence Classes" },
  { value: "Senior Secondary Schools", label: "Senior Secondary Schools" },
  { value: "Service Apartments", label: "Service Apartments" },
  { value: "Shoe Dealers", label: "Shoe Dealers" },
  { value: "Shoe Repair & Services", label: "Shoe Repair & Services" },
  { value: "Sign Board Dealers", label: "Sign Board Dealers" },
  { value: "Silver Jewellery Dealers", label: "Silver Jewellery Dealers" },
  { value: "Skin Care Clinics", label: "Skin Care Clinics" },
  { value: "Small Scale Industries", label: "Small Scale Industries" },
  { value: "Snack Centres", label: "Snack Centres" },
  { value: "Soap Dealers", label: "Soap Dealers" },
  {
    value: "Social Service Organisations",
    label: "Social Service Organisations",
  },
  { value: "Solar Equipment Dealers", label: "Solar Equipment Dealers" },
  { value: "Solar Panel Dealers", label: "Solar Panel Dealers" },
  { value: "Sound System Dealers", label: "Sound System Dealers" },
  { value: "Spa", label: "Spa" },
  { value: "Spare Parts Dealers", label: "Spare Parts Dealers" },
  { value: "Sporting Goods Dealers", label: "Sporting Goods Dealers" },
  { value: "Sports Academies", label: "Sports Academies" },
  { value: "Sports Coaching Centres", label: "Sports Coaching Centres" },
  { value: "Sports Clubs", label: "Sports Clubs" },
  { value: "Sports Shops", label: "Sports Shops" },
  { value: "Stationery Shops", label: "Stationery Shops" },
  { value: "Steel Dealers", label: "Steel Dealers" },
  { value: "Storage", label: "Storage" },
  { value: "Supermarkets", label: "Supermarkets" },
  { value: "Swimming Pools", label: "Swimming Pools" },
  { value: "Tailoring Classes", label: "Tailoring Classes" },
  { value: "Tailors", label: "Tailors" },
  { value: "Tattoo Studios", label: "Tattoo Studios" },
  { value: "Tea Dealers", label: "Tea Dealers" },
  { value: "Technical Institutes", label: "Technical Institutes" },
  { value: "Telecom Services", label: "Telecom Services" },
  { value: "Tent House Dealers", label: "Tent House Dealers" },
  { value: "Textile Dealers", label: "Textile Dealers" },
  { value: "Textile Manufacturers", label: "Textile Manufacturers" },
  { value: "Theatres", label: "Theatres" },
  { value: "Timber Dealers", label: "Timber Dealers" },
  { value: "Tour Operators", label: "Tour Operators" },
  { value: "Toys Dealers", label: "Toys Dealers" },
  { value: "Tractor Dealers", label: "Tractor Dealers" },
  { value: "Trade Schools", label: "Trade Schools" },
  { value: "Training Institutes", label: "Training Institutes" },
  { value: "Transport Services", label: "Transport Services" },
  { value: "Travel Agents", label: "Travel Agents" },
  { value: "Tree Cutting Services", label: "Tree Cutting Services" },
  { value: "Truck Dealers", label: "Truck Dealers" },
  { value: "Tuition Centres", label: "Tuition Centres" },
  { value: "TV Dealers", label: "TV Dealers" },
  { value: "TV Repair & Services", label: "TV Repair & Services" },
  { value: "Two Wheeler Dealers", label: "Two Wheeler Dealers" },
  { value: "Tyre Dealers", label: "Tyre Dealers" },
  { value: "Uniform Dealers", label: "Uniform Dealers" },
  { value: "Uniform Tailors", label: "Uniform Tailors" },
  { value: "Upholstery Dealers", label: "Upholstery Dealers" },
  { value: "Used Car Dealers", label: "Used Car Dealers" },
  { value: "Vegetable Dealers", label: "Vegetable Dealers" },
  { value: "Vehicle Rental Services", label: "Vehicle Rental Services" },
  { value: "Veterinary Clinics", label: "Veterinary Clinics" },
  { value: "Veterinary Doctors", label: "Veterinary Doctors" },
  { value: "Video Game Dealers", label: "Video Game Dealers" },
  { value: "Water Filter Dealers", label: "Water Filter Dealers" },
  {
    value: "Water Filter Repair & Services",
    label: "Water Filter Repair & Services",
  },
  { value: "Water Suppliers", label: "Water Suppliers" },
  { value: "Wedding Card Dealers", label: "Wedding Card Dealers" },
  { value: "Wedding Halls", label: "Wedding Halls" },
  { value: "Wedding Planners", label: "Wedding Planners" },
  { value: "Weighing Machine Dealers", label: "Weighing Machine Dealers" },
  { value: "Welding Services", label: "Welding Services" },
  { value: "Window Dealers", label: "Window Dealers" },
  {
    value: "Women Welfare Organisations",
    label: "Women Welfare Organisations",
  },
  { value: "X-Ray Clinics", label: "X-Ray Clinics" },
  { value: "Yoga Centres", label: "Yoga Centres" },
  { value: "Zoological Parks", label: "Zoological Parks" },
];

const searchItems = [
  {
    id: "stores",
    label: "Search by Stores",
    placeholder: "Select Store",
    icon: "fi-bs-search",
    border: "border-l-0",
  },
  {
    id: "area",
    label: "Search by Area",
    placeholder: "Select Area",
    icon: "fi-bs-marker",
    border: "border-l-2",
  },
  {
    id: "category",
    label: "Search by Category",
    placeholder: "Select Category",
    icon: "fi-bs-category",
    border: "border-l-2",
  },
];

const businessType = [
  "",
  "Service Provider | Selling Services",
  "Product Supplier | selling Products",
  "Both",
];

export {
  shopCategory,
  whereLocation,
  keywords,
  searchItems,
  businessType,
  keywordsDropdown,
};
