import axios from "axios";

const axiosInstance = axios.create({
  baseURL:
    process.env.REACT_APP_BACKENDURL_PROD ||
    "https://objectindia-backend.vercel.app/api/v1",
  timeout: 10000,
  withCredentials: true,
});

// Function to retrieve CSRF token from cookies
const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

// Axios request interceptor to set headers
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken"); // Retrieve JWT token from localStorage
    if (token) {
      // Set Authorization header with JWT token
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Set CSRF token from cookies
    const csrfToken = getCookie("XSRF-TOKEN");
    if (csrfToken) {
      config.headers["X-XSRF-TOKEN"] = csrfToken; // Set X-XSRF-TOKEN header
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const getUserDetails = async () => {
  try {
    const token = localStorage.getItem("accessToken");
    if (!token) throw new Error("No access token found");

    const response = await axiosInstance.get("/user/details");
    const user = response.data;

    return user;
  } catch (error) {
    console.error("Error fetching user details:", error.message);
    throw new Error("Error fetching user details");
  }
};

const getAllStores = async () => {
    try {
        const response = await axiosInstance.get(`/stores`);
        return response.data;
      } catch (error) {
        console.error("Error fetching store:", error.message);
        throw new Error("Error fetching store details");
      }
}

const getStoresByCategory = async (category) => {
  try {
    const response = await axiosInstance.get(`/stores/category/${category}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching store details by category:", error.message);
    throw new Error("Error fetching store details");
  }
};

const getStoreById = async (id) => {
  try {
    const response = await axiosInstance.get(`/details/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching store details by category:", error.message);
    throw new Error("Error fetching store details");
  }
};

const getAllCategories = async () => {
    try {
        const response = await axiosInstance.get("/searchData/categories");
        return response.data;
      } catch (error) {
        console.error("Error fetching category:", error.message);
        throw new Error("Error fetching ");
      }
}

export { axiosInstance, getUserDetails, getStoresByCategory, getStoreById, getAllCategories, getAllStores };
