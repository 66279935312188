import React, { useState, useEffect } from 'react';
import { getAllStores } from '../config/securityInstance';
import { Link } from 'react-router-dom';

function StoreCarouselListing() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [stores, setStores] = useState([]);
    const locationIcon = 'fi-bs-globe';

    // Fetch all stores on mount
    const fetchAllStores = async () => {
        const response = await getAllStores();
        if (response && Array.isArray(response)) {
            setStores(response);
        }
    };

    useEffect(() => {
        fetchAllStores();
    }, []);

    // Carousel auto-scroll effect
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentIndex((prevIndex) =>
                stores.length > 0 ? (prevIndex + 1) % stores.length : 0
            );
        }, 3000); // Display each store for 3 seconds

        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, [stores]);

    return (
        <div className="relative w-full max-w-96 mx-auto py-12 overflow-hidden">
            <div
                className="flex transition-transform ease-in-out duration-700"
                style={{
                    transform: `translateX(-${currentIndex * 100}%)`,
                    width: '100%'
                }}
            >
                {stores.map((store, index) => (
                    <Link
                        to={`/s/${store._id}`}
                        key={index}
                        className="w-full flex-shrink-0"
                        style={{
                            minWidth: '100%',
                            transition: 'transform 0.5s ease-in-out'
                        }}
                    >
                        <div className="bg-white border border-black rounded-lg shadow-xl min-h-[420px] max-h-[420px] flex flex-col items-center text-center mx-4">
                            <img
                                src={store.images[0]?.img1}
                                alt="shop"
                                className="w-full h-64 object-cover rounded-t-lg mb-4"
                                loading="lazy"
                            />
                            <div className="flex flex-col w-full justify-center px-4">
                                <h2 className="text-xl text-left font-semibold mb-2">
                                    {store.shopDetails.shopName || 'Store Name'}
                                </h2>
                                <p className="text-gray-500 text-lg text-left items-center mb-2">
                                    <span className="mr-2 mt-1">
                                        <i className={locationIcon}></i>
                                    </span>
                                    {store.shopLocationDetails.shopLocation ||
                                        'Location'}
                                </p>
                                <p className="text-gray-500 text-left text-lg">
                                    <span className="mr-2">
                                        <i className={locationIcon}></i>
                                    </span>
                                    {store.shopLocationDetails.shopArea ||
                                        'Phone'}
                                </p>
                                {store.shopLocationDetails.shopWebsite && (
                                    <p className="text-gray-500 text-left text-lg">
                                        <span className="mr-2">
                                            <i className={locationIcon}></i>
                                        </span>
                                        {store.shopLocationDetails
                                            .shopWebsite || 'Phone'}
                                    </p>
                                )}
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}

export default StoreCarouselListing;
