import Categories from './CategoriesHome';
import Hero from './Hero';
import HowItWorks from '../../components/UI/HowItWorks';
import StartListingHome from '../../components/UI/StartListingHome';
import '../../App.css';

function Home() {
    return (
        <header className="App-header py-20">
            <Hero />
            <Categories />
            <HowItWorks />
            <StartListingHome />
        </header>
    );
}

export default Home;
