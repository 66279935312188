// src/contexts/UserContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getUserDetails } from '../config/securityInstance';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchUser = async () => {
        setLoading(true);
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            try {
                const userDetails = await getUserDetails(accessToken);
                setUser(userDetails);
            } catch (error) {
                console.error('Error fetching user details:', error.message);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <UserContext.Provider value={{ user, setUser, loading, fetchUser }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
