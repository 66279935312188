import React, { useEffect, useState } from "react";
import TopHeadNameBarCategories from "../../components/UI/TopHeadNameBarCategories";
import CategoriesListingItems from "./CategoriesListingItems";
import SearchBox from "../../components/UI/SearchBox";
import { searchItems } from "../../data/searchData";
import { useLocation } from "react-router-dom";

function CategoriesSubListing() {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const [headName, setHeadName] = useState();
  const keyword = params.get("keyword");
  const location = params.get("location");
  const categoryName = window.location.pathname.replace("/c/", "");
  useEffect(() => {
    let tempName = window.location.pathname
      .replace("/c/", "")
      .replace("%20", " ");
    setHeadName(tempName);
  }, []);

  return (
    <div className="py-20">
      <TopHeadNameBarCategories headName={headName} />
      <div className="flex justify-center my-12">
        <div className="px-8">
          <SearchBox searchItems={searchItems} />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="w-full">
          <CategoriesListingItems
            categoryName={categoryName}
            keyword={keyword}
            location={location}
          />
        </div>
      </div>
    </div>
  );
}

export default CategoriesSubListing;
